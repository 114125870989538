import React from "react";
import ReactDOM from "react-dom";
import DashApp from "./dashApp";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import { initializeFirebase } from "./firebase/config";
import { fixLoading } from "./helpers/itemLoader";
var oldConsoleLog = console.log;
fixLoading();

function noop() {}

// if (process.env.REACT_APP_NODE_ENV !== "development") {
//   window["console"]["log"] = function() {};
//   console.warn = noop;
//   console.error = noop;
//   window["console"]["log"] = oldConsoleLog;
//   console.log(
//     "%cWarning! Please stop!",
//     "font-weight: bold; color:#EC4121 ;font-size: 50px; text-shadow:1px 1px 0px white, 1px -1px 0px white, -1px 1px 0px white, -1px -1px 0px white"
//   );
//   console.log(
//     "%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a d-पैसा feature, it is a scam and will instead give them access to your d-पैसा account.!!",
//     "font-size: 20px"
//   );
//   window["console"]["log"] = function() {};
  
// }

ReactDOM.render(<DashApp />, document.getElementById("root"));
initializeFirebase();
serviceWorker.register();
