import config, { getCurrentLanguage } from '../../containers/LanguageSwitcher/config';

import actions from './actions';
import secureStorage from '../../containers/LocalStorage';
const initState = {
	isActivated: false,
	language: secureStorage.getItem('language_name')
		? secureStorage.getItem('language_name')
		: getCurrentLanguage(config.defaultLanguage || 'english'),
};

export default function(state = initState, action) {
	switch (action.type) {
		case actions.ACTIVATE_LANG_MODAL:
			return {
				...state,
				isActivated: !state.isActivated,
			};
		case actions.CHANGE_LANGUAGE:
			secureStorage.setItem('language', action.language.locale);
			secureStorage.setItem('language_name', action.language.languageId);
			window.location.reload(true);
			return {
				...state,
				language: action.language,
			};

		default:
			return state;
	}
}
