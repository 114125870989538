const initState = {
    formData: {
    }
}

const walletReducer = (state = initState, action) => {
    switch (action.type) {
        case "UPDAE_KYC_FORM":
            let temp = { ...state.formData, ...action.values }
            return {
                ...state,
                formData: temp,
            };
        default:
            return state;
    }
}

export default walletReducer; 