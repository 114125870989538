//import axios from "../../../../axios/index";
//import secureStorage from "../../../../containers/LocalStorage";

export const getCashbacks = (list) => {
	return (dispatch, getState) => {
		dispatch({ type: 'GET_CASHBACKS', list });
	};
};


