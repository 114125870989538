import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Icon, Spin } from "antd";
import axios from "../../axios/index";
import secureStorage from "../LocalStorage";
import { errorResponse } from "../ErrorConfig";
const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      loading: false,
      referralCodeData: null,
      kyc:null,
      agent:null,
      sub_agent:null
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  handleLogout = async () => {
    try {
      this.setState({
        loading: true,
      });
      let logout = await axios.get("api/logout", {
        headers: {
          Authorization: "Bearer " + secureStorage.getItem("id_token"),
        },
      });
      this.setState({
        loading: false,
      });
      console.log("succcess", logout.data);
      await this.props.logout();
    } catch (e) {
      this.setState({
        loading: false,
        visible: false,
      });
      errorResponse(e);
      await this.props.logout();
    }
  };
  componentDidMount() {
    // Fetch the referral code data when the component mounts
    const asyncFunc =()=>{ 
    const referralCodeData = secureStorage.getItem("referral_code");
    const kyc = secureStorage.getItem("kyc");
    const agent= secureStorage.getItem("agent") 
    const sub_agent = secureStorage.getItem("sub_agent")
    this.setState({ referralCodeData });
    this.setState({ kyc });
    this.setState({ agent });
    this.setState({ sub_agent });
    }
    asyncFunc()
  
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if referralCodeData has changed
    if (prevState.referralCodeData !== this.state.referralCodeData) {
      // Perform an action when referralCodeData changes
     console.log("updateds")
    }
  }
  render() {

    const content = (
      <Spin spinning={this.state.loading} tip="Logging Out">
        <TopbarDropdownWrapper className="isoUserDropdown">
        
          <a className="isoDropdownLink" href="/dashboard/settings">
            <IntlMessages id="topbar.settings" />
          </a>
          <a className="isoDropdownLink" href="/dashboard/profile">
            <IntlMessages id="topbar.profile" />
          </a>
         
          {this.state.referralCodeData ? (
            <a className="isoDropdownLink" href="/dashboard/referral">
              <IntlMessages id="topbar.referral" />
            </a>
          ) : null}

          {this.state.kyc &&
          this.state.kyc === "VALID" &&
          (this.state.agent === "REJECTED" ||
          this.state.agent === null) &&
          (this.state.sub_agent === null ||
            this.state.sub_agent  === "REJECTED") ? ( 
            <a className="isoDropdownLink" href="/dashboard/becomeAgent">
              <IntlMessages id="topbar.become-agent" />
            </a>
          ) : null}

          {/* {secureStorage.getItem('kyc') &&
					secureStorage.getItem('kyc') === 'VALID' &&
					(secureStorage.getItem('agent') === 'REJECTED' || secureStorage.getItem('agent') === null) &&
					(secureStorage.getItem('sub_agent') === null ||
						secureStorage.getItem('sub_agent') === 'REJECTED') ? (
						<a className="isoDropdownLink" href="/dashboard/becomeSubAgent">
							<IntlMessages id="topbar.become-sub-agent" />
						</a>
					) : null}

					{secureStorage.getItem('agent') === 'ACCEPTED' ? (
						<a className="isoDropdownLink" href="/dashboard/CreateSubAgent">
							<IntlMessages id="topbar.create-sub-agent" />
						</a>
					) : null} */}

          {/* Deactivate deactivate */}
          <a
            className="isoDropdownLink"
            // href={this.handleLogout}
            //href="/signin/"
            onClick={console.log("deactivated clicked")}
          >
            <IntlMessages id="Deactivate" />
          </a>
          <a
            className="isoDropdownLink"
            href={this.handleLogout}
            //href="/signin/"
            onClick={this.handleLogout}
          >
            <IntlMessages id="topbar.logout" />
          </a>
        </TopbarDropdownWrapper>
      </Spin>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <Icon type="user" style={{ color: "#fff", fontSize: 25 }} />
      </Popover>
    );
  }
}
export default connect(null, { logout })(TopbarUser);
