import Auth from './auth/reducer';
import App from './app/reducer';
import Notification from './application/reducers/notification';
import Wallet from './application/reducers/wallet';
import Request from './application/reducers/request';
import BankList from './application/reducers/bankList/bankList'
import Transaction from './application/reducers/transactions';
import SendFund from './application/reducers/sendFunds/index'
import CurrentUser from './application/reducers/currentUser/index'
import KYC from './application/reducers/Kyc/index'
import FCM from './application/reducers/fcmToken/index'
import BonusPoints from './application/reducers/bonus/index'
import Cashbacks from './application/reducers/cashback/index'
import LanguageSwitcher from './languageSwitcher/reducer';


export default {
  Auth,
  App,
  Notification,
  Wallet,
  Request,
  BankList,
  Transaction,
  SendFund,
  CurrentUser,
  KYC,
  FCM,
  BonusPoints,
  Cashbacks,
  LanguageSwitcher,
};


