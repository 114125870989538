const initState = {
  requests: [],
  loading: true,
  acceptError: null,
  requestLength: 0
};

const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_REQUEST_SUCCESS":
      //console.log('request_notification_success')
      let count = action.data.data;
      const found = count.filter(element => element.isRead === false);
      return {
        ...state,
        requests: action.data,
        requestLength: found.length,
        loading: false
      };
    case "GET_REQUEST_ERROR":
      console.log("request_error", action.err);
      return state;
    case "ACCEPT_REQUEST_SUCCESS":
      let accept = state.requestLength;
      if (accept - 1 < 0) {
        return {
          ...state,
          requests: action.data,
          acceptError: "Accept Request Success",
          requestLength: 0,
          loading: false
        };
      } else {
        return {
          ...state,
          requests: action.data,
          acceptError: "Accept Request Success",
          requestLength: accept - 1,
          loading: false
        };
      }

    case "ACCEPT_REQUEST_ERROR":
      console.log("accept_request_error");
      return {
        ...state,
        acceptError: "Request Failed",
        loading: false
      };
    case "START_ACCEPT_REJECT_LOADING":
      return {
        ...state,
        loading: true
      };
    case "RESET_ACCEPT_REJECT":
      return {
        ...state,
        acceptError: null
      };
    case "REJECT_REQUEST_SUCCESS":
      //console.log('REJECT_REQUEST_SUCCESS', action.data)
      let reject = state.requestLength;
      if (reject - 1 < 0) {
        return {
          ...state,
          requests: action.data,
          requestLength: 0,
          loading: false
        };
      } else {
        return {
          ...state,
          requests: action.data,
          requestLength: reject - 1,
          loading: false
        };
      }

    case "REJECT_REQUEST_ERROR":
      console.log("REJECT_REQUEST_ERROR", action.err);
      return {
        ...state,
        loading: false
      };
    case "INCREASE_NOTIFICATION_COUNT":
      let temp = state.requestLength;
      return {
        ...state,
        requestLength: temp + 1
      };
    case "CLEAR_NOTIFICATION_COUNT":
      return {
        ...state,
        requestLength: 0
      };
    case "DECREASE_NOTIFICATION_COUNT":
      let n = state.requestLength;
      console.log(n - 1);
      if (n - 1 < 0) {
        return {
          ...state,
          requestLength: 0
        };
      } else {
        return {
          ...state,
          requestLength: n - 1
        };
      }

    default:
      return state;
  }
};

export default notificationReducer;
