import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isLoggedIn) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: "/signin/",
              state: { from: props.location }
            }}
          />
        );
      }
    }}
  />
);

const PublicRoutes = ({ history, isLoggedIn, auth }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <Route
            exact
            path={"/"}
            component={asyncComponent(() => import("./containers/Landing"))}
          />
          <Route
            exact
            path={"/signin"}
            component={asyncComponent(() => import("./containers/Page/signin"))}
          />
          <Route
            exact
            path={"/signup"}
            component={asyncComponent(() => import("./containers/Register"))}
          />
          <Route
            path={"/merchantPayment/:processId"}
            component={asyncComponent(() =>
              import("./containers/MerchantPayment/index")
            )}
          />
          <Route
            exact
            path={"/forgotPassword"}
            component={asyncComponent(() =>
              import("./containers/ForgotPassword/index")
            )}
          />
          <RestrictedRoute
            path="/dashboard"
            component={App}
            isLoggedIn={isLoggedIn}
          />
          <Route
            component={asyncComponent(() =>
              import("./containers/NotFound/index")
            )}
          />
          <Route
            path="/404"
            component={asyncComponent(() =>
              import("./containers/NotFound/index")
            )}
          />
          <Route
            path="*"
            component={asyncComponent(() =>
              import("./containers/NotFound/index")
            )}
          />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.id_token !== null
  //referrer: state.Auth.redirectToReferrer
}))(PublicRoutes);
