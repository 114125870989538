import Notification from "../../../../components/notification";
const initState = {
  banks: [],
  loadStatus: null,
  loading: true
};

const bankReducer = (state = initState, action) => {
  switch (action.type) {
    case "BANK_LIST_FETCH_SUCCESS":
      console.log("load_success", action.data);
      return {
        ...state,
        banks: action.data,
        loadStatus: "Success",
        loading: false
      };

    case "BANK_LIST_FETCH_FAILED":
      console.log("load_error", action.err.response);
      if (
        action.err.response &&
        action.err.response.data &&
        action.err.response.data.message &&
        action.err.response.data.status
      ) {
        Notification(
          "error",
          action.err.response.data.status,
          action.err.response.data.message
        );
      } else {
        Notification("error", "Bank List not found, Please try again later!!");
      }

      return {
        ...state,
        loadStatus: "FAILED",
        loading: false
      };
    default:
      return state;
  }
};

export default bankReducer;
