import actions from "./actions";
import Notification from "../../components/notification";

const initState = {
  error: "",
  loading: false,
  redirectToReferrer: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        redirectToReferrer: true,
      };
    case actions.LOGIN_ERROR:
      let error = action.error.status;
      if (error && error.data && error.data.status && error.data.message) {
        Notification("error", error.data.status, error.data.message);
      } else {
        Notification(
          "error",
          "3000",
          "Login Failed, Invalid Login Credentials!!"
        );
      }

      return {
        error: true,
        message: action.error.message,
        status: action.error.status,
        loading: false,
      };
    case actions.LOGOUT:
      return initState;
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
