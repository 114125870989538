const initState = {
    desktop_fcm: null,
    spinning: true
}

const fcmReducer = (state = initState, action) => {
    switch (action.type) {

        case "STORE_FCM_TOKEN":
            return {
                ...state,
                desktop_fcm: action.token,
                spinning: false
            }
        default:
            return state
    }
}
export default fcmReducer