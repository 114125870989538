import axios from "../../../../axios/index";
import secureStorage from "../../../../containers/LocalStorage";
import {
  getFundRequestApi,
  acceptFuntRequestApi,
  rejectFuntRequestApi
} from "../../../../containers/UrlConfig";
export const getRequests = () => {
  return (dispatch, getState) => {
    axios
      .get(getFundRequestApi, {
        headers: {
          Authorization: "Bearer " + secureStorage.getItem("id_token")
        }
      })
      .then(({ data }) => {
        dispatch({ type: "GET_REQUEST_SUCCESS", data });
      })
      .catch(err => {
        dispatch({ type: "GET_REQUEST_ERROR", err });
      });
  };
};
//fund request accept and update list
export const acceptRequest = (id, amount, actionId) => {
  return (dispatch, getState) => {
    axios
      .post(
        acceptFuntRequestApi + id + "/" + actionId + "/accept",
        {},
        {
          headers: {
            Authorization: "Bearer " + secureStorage.getItem("id_token")
          }
        }
      )
      .then(({ data }) => {
        dispatch({ type: "UPDATE_WALLET", amount });
        axios
          .get(getFundRequestApi, {
            headers: {
              Authorization: "Bearer " + secureStorage.getItem("id_token")
            }
          })
          .then(({ data }) => {
            dispatch({ type: "ACCEPT_REQUEST_SUCCESS", data });
          })
          .catch(err => {
            dispatch({ type: "ACCEPT_REQUEST_ERROR", err });
          });
      })
      .catch(err => {
        dispatch({ type: "ACCEPT_REQUEST_ERROR", err });
      });
  };
};

//spinner status change after accepting or rejecting
export const startLoading = () => {
  return (dispatch, getState) => {
    dispatch({ type: "START_ACCEPT_REJECT_LOADING" });
  };
};
export const updateNotificationCount = type => {
  return (dispatch, getState) => {
    if (type === "increase") {
      dispatch({ type: "INCREASE_NOTIFICATION_COUNT" });
    } else if (type === "decrease") {
      dispatch({ type: "DECREASE_NOTIFICATION_COUNT" });
    } else if (type === "clear") {
      dispatch({ type: "CLEAR_NOTIFICATION_COUNT" });
    }
  };
};

//resetting the error message
export const resetAcceptReject = () => {
  return dispatch => {
    dispatch({ type: "RESET_ACCEPT_REJECT" });
  };
};

//rejecting fund request
export const rejectRequest = (id, actionId) => {
  return (dispatch, getState) => {
    axios
      .post(
        rejectFuntRequestApi + id + "/" + actionId + "/reject",
        {},
        {
          headers: {
            Authorization: "Bearer " + secureStorage.getItem("id_token")
          }
        }
      )
      .then(({ data }) => {
        axios
          .get(getFundRequestApi, {
            headers: {
              Authorization: "Bearer " + secureStorage.getItem("id_token")
            }
          })
          .then(({ data }) => {
            dispatch({ type: "REJECT_REQUEST_SUCCESS", data });
          })
          .catch(err => {
            dispatch({ type: "REJECT_REQUEST_ERROR", err });
          });
      })
      .catch(err => {
        dispatch({ type: "REJECT_REQUEST_ERROR", err });
      });
  };
};
