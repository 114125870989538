const initState = {
    singleTransaction: {}
}

const transactionReducer = (state = initState, action) => {
    switch (action.type) {

        case "STORE_SINGLE_TRANSACTION":
            //console.log('load_success', action.data)
            return {
                ...state,
                singleTransaction: action.singleTransaction,
            }
        default:
            return state
    }

}

export default transactionReducer