import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import secureStorage from '../LocalStorage';

const routes = [
	{
		path: '',
		component: asyncComponent(() => import('../Home')),
	},
	{
		path: 'home',
		component: asyncComponent(() => import('../Home')),
	},
	{
		path: 'authCheck',
		component: asyncComponent(() => import('../AuthCheck')),
	},
	{
		path: 'demo',
		component: asyncComponent(() => import('../demopage')),
	},
	{
		path: 'loadFunds',
		component: asyncComponent(() => import('../LoadFunds')),
	},
	{
		path: 'loadFunds/ebanking',
		component: asyncComponent(() => import('../LoadFunds/Ebanking')),
	},
	{
		path: 'loadFunds/ebanking/amount/:id',
		component: asyncComponent(() => import('../LoadFunds/Ebanking/EnterAmount')),
	},
	{
		path: 'loadFunds/mobilebanking',
		component: asyncComponent(() => import('../LoadFunds/MobileBanking')),
	},
	{
		path: 'loadFunds/visa',
		component: asyncComponent(() => import('../LoadFunds/Visa')),
	},
	{
		path: 'loadFunds/visa/amount',
		component: asyncComponent(() => import('../LoadFunds/Visa/EnterAmount')),
	},
	{
		path: 'loadFunds/sct',
		component: asyncComponent(() => import('../LoadFunds/SctCard')),
	},
	{
		path: 'loadFunds/ips',
		component: asyncComponent(() => import('../LoadFunds/IpsConnect')),
	},
	{
		path: 'loadFunds/request',
		component: asyncComponent(() => import('../LoadFunds/Request')),
	},
	{
		path: 'sendFunds',
		component: asyncComponent(() => import('../SendFunds')),
	},
	{
		path: 'bankDeposit',
		component: asyncComponent(() => import('../BankDeposit')),
	},
	// {
	// 	path: 'bankDeposit',
	// 	component: asyncComponent(() => import('../BankDepositNPS')),
	// }
	
	{
		path: 'profile',
		component: asyncComponent(() => import('../Profile')),
	},
	{
		path: 'changePassword',
		component: asyncComponent(() => import('../ChangePassword')),
	},
	{
		path: 'qrcode',
		component: asyncComponent(() => import('../QRCode')),
	},
	{
		path: 'transactionHistory',
		component: asyncComponent(() => import('../Transactions')),
	},
	{
		path: 'transactionHistory/singleTransaction/:id',
		component: asyncComponent(() => import('../Transactions/SingleTransaction/SingleTransaction')),
	},
	// {
	// 	path: 'cooperativeStatement',
	// 	component: asyncComponent(() => import('../CooperativeStatement')),
	// },
	{
		path: 'limits',
		component: asyncComponent(() => import('../Limits')),
	},
	{
		path: 'topup',
		component: asyncComponent(() => import('../Topup')),
	},
	{
		path: 'landline',
		component: asyncComponent(() => import('../Landline')),
	},
	{
		path: 'electricity',
		component: asyncComponent(() => import('../Electricity')),
	},
	{
		path: 'notifications',
		component: asyncComponent(() => import('../../containers/Topbar/Notifications/Notifications')),
	},
	{
		path: 'khanepani',
		component: asyncComponent(() => import('../Khanepani')),
	},
	{
		path: 'nepalWater',
		component: asyncComponent(() => import('../NepalWater')),
	},
	{
		path: 'arrowNet',
		component: asyncComponent(() => import('../Internet/ArrowNet')),
	},
	{
		path: 'adsl',
		component: asyncComponent(() => import('../Internet/Adsl')),
	},
	{
		path: 'wimax',
		component: asyncComponent(() => import('../Internet/Wimax')),
	},
	{
		path: 'ntftth',
		component: asyncComponent(() => import('../Internet/Ntftth')),
	},
	{
		path: 'sky/:type',
		component: asyncComponent(() => import('../Television/Sky')),
	},
	{
		path: 'webSurfer',
		component: asyncComponent(() => import('../Internet/WebSurfer')),
	},
	{
		path: 'subisu/:type',
		component: asyncComponent(() => import('../Television/Subisu')),
	},
	{
		path: 'worldLink',
		component: asyncComponent(() => import('../Internet/WorldLink')),
	},
	{
		path: 'dishHome',
		component: asyncComponent(() => import('../Television/DishHome')),
	},
	{
		path: 'simTv',
		component: asyncComponent(() => import('../Television/SimTv')),
	},
	{
		path: 'vianet',
		component: asyncComponent(() => import('../Internet/Vianet')),
	},
	{
		path: 'eastlink',
		component: asyncComponent(() => import('../Internet/EastLink')),
	},
	{
		path: 'infonet',
		component: asyncComponent(() => import('../Internet/Infonet')),
	},
	{
		path: 'infonetCommunication',
		component: asyncComponent(() => import('../Internet/InfonetCommunication')),
	},
	{
		path: 'metrolink',
		component: asyncComponent(() => import('../Internet/Metrolink')),
	},
	{
		path: 'pokharaNet',
		component: asyncComponent(() => import('../Internet/PokharaNet')),
	},
	{
		path: 'royalNet',
		component: asyncComponent(() => import('../Internet/RoyalNet')),
	},
	{
		path: 'webNetwork',
		component: asyncComponent(() => import('../Internet/WebNetwork')),
	},
	{
		path: 'virtualNet',
		component: asyncComponent(() => import('../Internet/VirtualNet')),
	},
	{
		path: 'kritiDarshan',
		component: asyncComponent(() => import('../Internet/KritiDarshan')),
	},
	{
		path: 'metaLink',
		component: asyncComponent(() => import('../Internet/MetaLink')),
	},
	{
		path: 'nijgadhNet',
		component: asyncComponent(() => import('../Internet/NijgadhNet')),
	},
	{
		path: 'broadbandNepal',
		component: asyncComponent(() => import('../Internet/BroadbandNepal')),
	},
	{
		path: 'classicTech',
		component: asyncComponent(() => import('../Internet/ClassicTech')),
	},
	{
		path: 'loopNetwork',
		component: asyncComponent(() => import('../Internet/LoopNetwork')),
	},
	{
		path: 'techminds',
		component: asyncComponent(() => import('../Internet/Techminds')),
	},
	{
		path: 'ultranet',
		component: asyncComponent(() => import('../Internet/Ultranet')),
	},
	{
		path: 'chitrawanInternet',
		component: asyncComponent(() => import('../Internet/ChitrawanInternet')),
	},
	{
		path: 'everestWireless',
		component: asyncComponent(() => import('../Internet/EverestWireless')),
	},
	{
		path: 'NtcEpin',
		component: asyncComponent(() => import('../Epin/NtcEpin')),
	},
	{
		path: 'NcellData',
		component: asyncComponent(() => import('../DataPack/NcellData')),
	},
	{
		path: 'NtcData',
		component: asyncComponent(() => import('../DataPack/NtcData')),
	},
	{
		path: 'SmartCellEpin',
		component: asyncComponent(() => import('../Epin/SmartCellEpin')),
	},
	{
		path: 'DishHomeEpin',
		component: asyncComponent(() => import('../Epin/DishHomeEpin')),
	},
	{
		path: 'BroadlinkEpin',
		component: asyncComponent(() => import('../Epin/BroadlinkEPIN')),
	},
	{
		path: 'UTLEpin',
		component: asyncComponent(() => import('../Epin/UTLEpin')),
	},
	{
		path: 'NetTv',
		component: asyncComponent(() => import('../Television/NetTv')),
	},
	{
		path: 'MeroTv',
		component: asyncComponent(() => import('../Television/MeroTv')),
	},
	{
		path: 'ClearTv',
		component: asyncComponent(() => import('../Television/ClearTv')),
	},
	{
		path: 'JagritiNational',
		component: asyncComponent(() => import('../Television/JagritiNational')),
	},
	{
		path: 'NijgadhTv',
		component: asyncComponent(() => import('../Television/NijgadhTv')),
	},
	{
		path: 'PrabhuTv',
		component: asyncComponent(() => import('../Television/PrabhuTv')),
	},
	{
		path: 'PNGNetwork',
		component: asyncComponent(() => import('../Television/PNGNetwork')),
	},
	{
		path: 'MaxTv',
		component: asyncComponent(() => import('../Television/MaxTv')),
	},
	{
		path: 'Tootle',
		component: asyncComponent(() => import('../RideSharing/Tootle')),
	},
	{
		path: 'CableCar/Manakamana',
		component: asyncComponent(() => import('../CableCar/Manakamana')),
	},
	{
		path: 'updatekyc',
		component: asyncComponent(() => import('../UpdateKYC')),
	},
	{
		path: 'generalPages/:id',
		component: asyncComponent(() => import('../GeneralPages')),
	},
	{
		path: 'settings',
		component: asyncComponent(() => import('../SetPin')),
	},
	// {
	// 	path: 'bounce',
	// 	component: asyncComponent(() => import('../Bounce')),
	// },
	{
		path: 'becomeAgent',
		component: asyncComponent(() => import('../BecomeAgent')),
	},
	// {
	// 	path: 'becomeSubAgent',

	// 	component: asyncComponent(() => import('../SubAgent')),
	// },
	// {
	// 	path: 'createSubAgent',

	// 	component: asyncComponent(() => import('../CreateSubAgent')),
	// },
	{
		path: 'referral',
		component: asyncComponent(() =>
			secureStorage.getItem('referral_code') ? import('../Referral') : import('../NotFound/index')
		),
	},
	{
		path: 'utilityPayments/singleTransaction/:id',
		component: asyncComponent(() => import('../UtilityPaymentSingle')),
	},
	{
		path: 'credit-card',
		component: asyncComponent(() => import('../CreditCard')),
	},
	{
		path: 'broker-payment',
		component: asyncComponent(() => import('../BrokerPayment')),
	},
	// {
	// 	path: 'mero-share',
	// 	component: asyncComponent(() => import('../MeroShare')),
	// },
	// {
	// 	path: 'demat',
	// 	component: asyncComponent(() => import('../Demat')),
	// },
	{
		path: 'school',
		component: asyncComponent(() => import('../Education/School')),
	},
	{
		path: 'college',
		component: asyncComponent(() => import('../Education/College')),
	},
	{
		path: 'midas',
		component: asyncComponent(() => import('../Education/Midas')),
	},
	{
		path: 'medical-exam',
		component: asyncComponent(() => import('../Education/MedicalExam')),
	},
	{
		path: 'events',
		component: asyncComponent(() => import('../Events')),
	},
	{
		path: 'buy-ticket/:id',
		component: asyncComponent(() => import('../Events/BuyTicket')),
	},
	{
		path: 'ird',
		component: asyncComponent(() => import('../GovernmentPayments')),
	},
	{
		path: 'passport',
		component: asyncComponent(() => import('../GovernmentPayments')),
	},
	{
		path: 'loksewa',
		component: asyncComponent(() => import('../GovernmentPayments')),
	},
	{
		path: 'cro',
		component: asyncComponent(() => import('../GovernmentPayments')),
	},
	{
		path: 'fcgo',
		component: asyncComponent(() => import('../GovernmentPayments')),
	},
	{
		path: 'dofe',
		component: asyncComponent(() => import('../GovernmentPayments')),
	},
	{
		path: 'trafficFine',
		component: asyncComponent(() => import('../TrafficFine')),
	},
	{
		path: 'antivirus',
		component: asyncComponent(() => import('../Antivirus')),
	},
	{
		path: 'cooperative/link',
		component: asyncComponent(() => import('../Magnus/LinkACcountForm')),
	},
	{
		path: 'cooperative/load-list',
		component: asyncComponent(() => import('../Magnus/LinkedAccountList')),
	},
	{
		path: 'cooperative/load',
		component: asyncComponent(() => import('../Magnus')),
	},
	{
		path: 'cooperative/deposit-list',
		component: asyncComponent(() => import('../Magnus/LinkedAccountList')),
	},
	{
		path: 'cooperative/deposit',
		component: asyncComponent(() => import('../Magnus')),
	},
	// {
	// 	path: 'travel',
	// 	component: asyncComponent(() => import('../Travel')),
	// },
	// {
	// 	path: 'government',
	// 	component: asyncComponent(() => import('../Government')),
	// },
	// {
	// 	path: 'cit',
	// 	component: asyncComponent(() => import('../CIT')),
	// },
	// {
	// 	path: 'bonus-tax',
	// 	component: asyncComponent(() => import('../BonusTax')),
	// },
	// {
	// 	path: 'life-insurance',
	// 	component: asyncComponent(() => import('../LifeInsurance')),
	// },
];

class AppRouter extends Component {
	render() {
		const { url, style } = this.props;
		return (
			<div style={style}>
				<Switch>
					{routes.map((singleRoute) => {
						const { path, exact, ...otherProps } = singleRoute;
						return (
							<Route exact key={singleRoute.path} path={`${url}/${singleRoute.path}`} {...otherProps} />
						);
					})}
					<Route path="/dashboard/*" component={asyncComponent(() => import('../NotFound/index'))} />
				</Switch>
			</div>
		);
	}
}

export default AppRouter;
