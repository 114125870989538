const initState = {
	cashbacks: [],
};

const cashbackReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_CASHBACKS':
			//console.log('wallet_success', action)
			return {
				...state,
				cashbacks: action.list,
			};
		default:
			return state;
	}
};

export default cashbackReducer;
