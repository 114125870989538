import SecureStorage from "secure-web-storage";
const CryptoJS = require("crypto-js");

const LOCAL_STORAGE = process.env.REACT_APP_LOCAL_STORAGE;
const SECRET_KEY = LOCAL_STORAGE;
console.log(LOCAL_STORAGE);
const secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    try {
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    } catch (e) {
      return null;
    }
  },
});
export default secureStorage;
