import { language } from '../../settings';

import englishLang from '../../image/flag/uk.svg';
import spanishLang from '../../image/flag/nepali.png';

const config = {
	defaultLanguage: language,
	options: [
		{
			languageId: 'english',
			locale: 'en',
			text: 'English',
			icon: englishLang,
		},
		{
			languageId: 'spanish',
			locale: 'es',
			text: 'Nepali',
			icon: spanishLang,
		},
	],
};

export function getCurrentLanguage(lang) {
	let selecetedLanguage = config.options[0];
	config.options.forEach((language) => {
		if (language.languageId === lang) {
			selecetedLanguage = language;
		}
	});
	return selecetedLanguage;
}

export default config;
