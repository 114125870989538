const initState = {
	bonus: 0,
	bonusList: [],
};

const bonusPointsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_BONUS':
			//console.log('wallet_success', action)
			return {
				...state,
				bonus: action.bonus,
			};
		case 'GET_BONUS_LIST':
			//console.log('wallet_success', action)
			return {
				...state,
				bonusList: action.list,
			};
		case 'GET_BONUS_ERROR':
			console.log('bonus_error', action.err)
			return state
		default:
			return state;
	}
};

export default bonusPointsReducer;
