import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/languageSwitcher/actions';
import config from './config';
import secureStorage from '../LocalStorage';
import { Badge } from 'antd';

const { changeLanguage } = actions;

class LanguageSwitcher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customClass: secureStorage.getItem('language_name') ? secureStorage.getItem('language_name') : 'english',
		};
	}
	render() {
		const { changeLanguage } = this.props;
		return (
			<div className="themeSwitchBlock">
				<div className="themeSwitchBtnWrapper">
					{config.options.map((option) => {
						const { languageId, icon } = option;
						return (
							<Badge
								dot={this.state.customClass === languageId ? true : false}
								size="default"
								style={{ backgroundColor: '#52c41a', marginRight: 5 }}
							>
								<button
									type="button"
									className={
										languageId === this.state.customClass ? 'languageSwitch' : 'languageSwitch'
									}
									style={{
										//backgroundColor: this.state.customClass === languageId ? '#EC4121' : null,
										marginRight: 5,
									}}
									key={languageId}
									onClick={() => {
										changeLanguage(languageId);
									}}
								>
									<img src={process.env.PUBLIC_URL + icon} alt="flag" style={{ width: 25 }} />
								</button>
							</Badge>
						);
					})}
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		...state.LanguageSwitcher,
	}),
	{ changeLanguage }
)(LanguageSwitcher);
