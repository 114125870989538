import Notification from '../../components/notification';
import { vendorErrorData } from '../UrlConfig';
function errorResponse(error) {
	let errorResponse = error.response;
	if (errorResponse && errorResponse.data) {
		if (errorResponse.data.errors) {
			Object.keys(errorResponse.data.errors).forEach((errors) => {
				console.log(errorResponse.data.errors[errors][0]);
				Notification(
					'error',
					//'Vendor Error Code: 014',
					errorResponse.data.errors[errors][0]
				);
			});
		} else if (errorResponse.data.message) {
			if (errorResponse.data.vendor_error_code) {
				Notification(
					'error',
					// 'Vendor Error Code: ' + errorResponse.data.vendor_error_code,
					vendorErrorData[errorResponse.data.vendor_error_code]
				);
			} else if (!errorResponse.data.vendor_error_code && typeof errorResponse.data.message !== 'object') {
				Notification('error', errorResponse.data.message);
			} else {
				Notification('error', 'Could not process request. Please try again later.');
			}
		} else {
			Notification('error', 'Could not process request. Please try again later.');
		}
	} else {
		Notification('error', 'Could not process request. Please try again later.');
	}
}

export { errorResponse };
