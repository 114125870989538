import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Spin, Alert } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import AppHolder from "./commonStyle";
import "./global.css";
import { Link } from "react-router-dom";
//for right sidebar
import { Row, Col } from "antd";
import LayoutWrapper from "../../components/utility/layoutWrapper.js";
import basicStyle from "../../settings/basicStyle";
import IsoWidgetsWrapper from "./widgets-wrapper";
import { Card, Avatar, List } from "antd";
import firebase from "firebase/app";
import {
  getRequests,
  updateNotificationCount,
} from "../../redux/application/actions/notification/index";
// import { Redirect } from 'react-router-dom';
import OTPVerification from "../OTPVerification";
import { Redirect } from "react-router-dom";
import {
  getCurrentUser,
  resetUser,
} from "../../redux/application/actions/currentUser/index";
import { responseWallet } from "../../redux/application/actions/wallet";
import { addToWallet } from "../../redux/application/actions/wallet/index";
import { notification } from "antd";
import { removeIncludes } from "../../helpers/itemLoader";
import secureStorage from "../LocalStorage";
import axios from "../../axios";
import { getBonusList } from "../../redux/application/actions/bonus";
import { getCashbacks } from "../../redux/application/actions/cashback";
import IntlMessages from "../../components/utility/intlMessages";
import Cookies from "js-cookie";
//right sidebar end
const { Content } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;

let messaging;

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      not_count: this.props.not_count,
      kycStatus: true,
      redirectToReferrer: this.props.referrer,
      loading: false,
      display: false,
      generals: [],
      dispalyText: null,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      not_count: props.not_count,
    });
  }
  async componentDidMount() {
    this.getGeneralInfo();
    this.getBonus();
    this.getCashbacks();
    await this.props.getCurrentUser();
    await this.getMessage();
    await this.props.getRequests();
    removeIncludes();
    await setTimeout(() => {
      if (secureStorage.getItem("kyc") === null) {
        this.setState({
          display: true,
          dispalyText: <IntlMessages id="wallet.fill-kyc" />,
        });
      } else if (secureStorage.getItem("kyc") === "REJECTED") {
        this.setState({
          display: true,
          dispalyText: <IntlMessages id="wallet.rejected-kyc" />,
        });
      }
    }, 3000);
  }

  getBonus = async () => {
    try {
      let bonus = await axios.get(`api/bonuses`, {
        headers: {
          Authorization: "Bearer " + secureStorage.getItem("id_token"),
        },
      });
      this.props.getBonusList(bonus.data.data);
    } catch (e) {
      console.log("err", e);
    }
  };

  getCashbacks = async () => {
    try {
      let cashback = await axios.get(`api/cash-backs`, {
        headers: {
          Authorization: "Bearer " + secureStorage.getItem("id_token"),
        },
      });
      this.props.getCashbacks(cashback.data.data);
    } catch (e) {
      console.log("err", e);
    }
  };

  getGeneralInfo = async () => {
    try {
      let general = await axios.get(`api/general-pages`, {
        // headers: {
        // 	Authorization: 'Bearer ' + secureStorage.getItem('id_token'),
        // },
      });
      this.setState({
        generals: general.data,
      });
    } catch (e) {
      console.log("err", e.response);
    }
  };
  openNotification = (title, desc) => {
    notification.info({
      message: title,
      description: desc,
      placement: "topLeft",
      onClick: () => this.props.history.push("/dashboard/notifications"),
    });
  };
  getMessage = () => {
    try {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        console.log("payload", payload);
        if (payload.data.type === "RequestFund") {
          this.openNotification(
            payload.notification.title,
            payload.notification.body
          );
          this.props.updateNotificationCount("increase");
        } else if (payload.data.type === "ReceievedFund") {
          this.props.responseWallet();
          this.openNotification(
            payload.notification.title,
            payload.notification.body
          );
          this.props.updateNotificationCount("increase");
        } else if (payload.data.type === "AcceptFund") {
          this.props.responseWallet();
          this.openNotification(
            payload.notification.title,
            payload.notification.body
          );
          this.props.updateNotificationCount("increase");
        } else if (payload.data.type === "KYCRejected") {
          secureStorage.setItem("kyc", "REJECTED");
          this.props.updateNotificationCount("increase");
          this.openNotification(
            payload.notification.title,
            payload.notification.body
          );
        } else if (payload.data.type === "KYCAccepted") {
          secureStorage.setItem("kyc", "VALID");
          this.props.updateNotificationCount("increase");
          this.openNotification(
            payload.notification.title,
            payload.notification.body
          );
        } else {
          this.props.updateNotificationCount("increase");
          this.openNotification(
            payload.notification.title,
            payload.notification.body
          );
        }
      });
    } catch (e) {
      console.log("cant supprot");
    }
  };

  getToken = async () => {
    const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_PUBLIC_KEY;
    try {
      messaging = firebase.messaging();
      messaging.usePublicVapidKey(FIREBASE_KEY);
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            console.log("token", currentToken);

            //sendTokenToServer(currentToken);
            //updateUIForPushEnabled(currentToken);
          } else {
            // Show permission request.
            console.log(
              "No Instance ID token available. Request permission to generate one."
            );
            // Show permission UI.
            //updateUIForPushPermissionRequired();
            //setTokenSentToServer(false);
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          //showToken('Error retrieving Instance ID token. ', err);
          //setTokenSentToServer(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  askPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        this.getToken();
        // TODO(developer): Retrieve an Instance ID token for use with FCM.
        // ...
      } else {
        console.log("Unable to get permission to notify.");
      }
    });
  };

  render() {
    // console.log(secureStorage.getItem("kyc"))
    const from = { pathname: "/signin/" };
    if (!Cookies.get("token")) {
      this.props.logout();
      return <Redirect to={from} />;
    }
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;
    const { wallet } = this.props;
    const { rowStyle, colStyle } = basicStyle;
    const wisgetPageStyle = {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      overflow: "hidden",
      width: "100%",
    };
    let kyc_status;
    console.log(secureStorage);
    // if (secureStorage.getItem("kyc") === null) {
    //   kyc_status = "Incomplete";
    // } else if (secureStorage.getItem("kyc") === "PROCESSING") {
    //   kyc_status = "Processing";
    // } else if (secureStorage.getItem("kyc") === "VALID") {
    //   kyc_status = "KYC Verified";
    // } else if (secureStorage.getItem("kyc") === "REJECTED") {
    //   kyc_status = "KYC Rejected";
    // }
    const data = [
      {
        title: <IntlMessages id="wallet.total" />,
        avatar: "/icons/Wallet-icon.png",
        description: `Rs. ${(
          parseFloat(wallet.wallet) + parseFloat(wallet.bonus_balance)
        ).toFixed(2)}`,
      },
      {
        title: <IntlMessages id="wallet.wallet" />,
        avatar:
          secureStorage.getItem("agent") === "ACCEPTED" ||
          secureStorage.getItem("sub_agent") === "ACCEPTED"
            ? "/icons/Wallet-icon.png"
            : "/favicon.png",
        description: "Rs." + parseFloat(wallet.wallet).toFixed(2),
      },
      {
        title: <IntlMessages id="wallet.bonus" />,
        avatar: "/icons/bonus_amount.png",
        description: "Rs. " + parseFloat(wallet.bonus_balance).toFixed(2),
      },

      {
        title: <IntlMessages id="wallet.kyc" />,
        avatar: "/icons/kyc.png",
        description: kyc_status,
      },
      {
        title: <IntlMessages id="wallet.agent" />,
        avatar: "/icons/agent.png",
        description: secureStorage.getItem("agent"),
      },
      {
        title: <IntlMessages id="wallet.sub-agent" />,
        avatar: "/icons/agent.png",
        description: secureStorage.getItem("sub_agent"),
      },
    ];

    var valid = new Date(secureStorage.getItem('verified')).getTime();
    //console.log(valid)

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={(windowSize) =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            {/* 
          {this.state.kycStatus ? <KYC /> : null} */}

            {this.state.display === true ? (
              <Link to="/dashboard/updateKYC">
                <Alert
                  message={this.state.dispalyText}
                  type={
                    secureStorage.getItem("kyc") === "REJECTED"
                      ? "error"
                      : "warning"
                  }
                  showIcon
                  closable
                  banner
                />
              </Link>
            ) : null}

            <Topbar url={url} not_count={this.state.not_count} />
            <Spin spinning={this.props.loading}>
              <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                {isNaN(valid) || valid <= 1 ? null : (
                  <Sidebar url={url} />
                )}

                <Layout
                  className="isoContentMainLayout"
                  style={{
                    height: height,
                  }}
                >
                  <Content
                    className="isomorphicContent"
                    style={{
                      padding: "70px 0 0",
                      flexShrink: "0",
                      background: "#f1f3f6",
                      position: "relative",
                    }}
                  >
                    <LayoutWrapper style={{ background: "" }}>
                      <div style={wisgetPageStyle}>
                        <Row style={rowStyle} gutter={0} justify="center">
                          <Col
                            lg={
                               isNaN(valid) || valid <= 1
                                ? 24
                                : 18
                            }
                            md={24}
                            sm={24}
                            xs={24}
                            style={colStyle}
                          >
                            {/** content */}
                            { isNaN(valid) || valid <= 1 ? (
                              <center>
                                <OTPVerification />
                              </center>
                            ) : (
                              <AppRouter url={url} />
                            )}

                            {/* content end */}
                          </Col>

                          {/* right sidebar */}

                          { isNaN(valid) || valid <= 1 ? null : (
                            <Col
                              lg={6}
                              md={24}
                              sm={24}
                              xs={24}
                              style={colStyle}
                            >
                              <Row style={rowStyle} gutter={0} justify="start">
                                <Col
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                  style={colStyle}
                                >
                                  <List
                                    size="large"
                                    bordered
                                    style={{
                                      borderRadius: 20,
                                      background: "#fff",
                                      borderColor: "#EC4121",
                                      marginLeft: 10,
                                    }}
                                  >
                                    {secureStorage.getItem("agent") ===
                                      "ACCEPTED" ||
                                    secureStorage.getItem("sub_agent") ===
                                      "ACCEPTED" ? null : (
                                      <List.Item
                                        style={{
                                          borderColor: "#EC4121",
                                        }}
                                      >
                                        <List.Item.Meta
                                          avatar={
                                            <Avatar
                                              src={data[0].avatar}
                                              size="large"
                                            />
                                          }
                                          title={data[0].title}
                                          description={
                                            <h3>{data[0].description}</h3>
                                          }
                                        />
                                      </List.Item>
                                    )}

                                    <List.Item
                                      style={{
                                        borderColor: "#EC4121",
                                      }}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            src={data[1].avatar}
                                            size="large"
                                          />
                                        }
                                        title={
                                          secureStorage.getItem("agent") ===
                                            "ACCEPTED" ||
                                          secureStorage.getItem("sub_agent") ===
                                            "ACCEPTED"
                                            ? data[0].title
                                            : data[1].title
                                        }
                                        description={
                                          <h3>{data[1].description}</h3>
                                        }
                                      />
                                    </List.Item>
                                    <List.Item
                                      style={{
                                        borderColor: "#EC4121",
                                      }}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            src={data[2].avatar}
                                            size="large"
                                          />
                                        }
                                        title={data[2].title}
                                        description={
                                          <h3>{data[2].description}</h3>
                                        }
                                      />
                                    </List.Item>
                                    <List.Item
                                      style={{
                                        borderColor: "#EC4121",
                                      }}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            src={data[3].avatar}
                                            size="large"
                                          />
                                        }
                                        title={data[3].title}
                                        description={
                                          <h3>{data[3].description}</h3>
                                        }
                                      />
                                    </List.Item>

                                    {secureStorage.getItem("agent") ? (
                                      <List.Item
                                        style={{
                                          borderColor: "#EC4121",
                                        }}
                                      >
                                        <List.Item.Meta
                                          avatar={
                                            <Avatar
                                              src={data[4].avatar}
                                              size="large"
                                            />
                                          }
                                          title={data[4].title}
                                          description={
                                            <>
                                              <h3>{data[4].description}</h3>
                                              {secureStorage.getItem(
                                                "agent"
                                              ) === "ACCEPTED" ? (
                                                <h4>
                                                  <IntlMessages id="wallet.agent-code" />
                                                  :{" "}
                                                  {secureStorage.getItem(
                                                    "agent_code"
                                                  )}
                                                </h4>
                                              ) : null}
                                            </>
                                          }
                                        />
                                      </List.Item>
                                    ) : // )  secureStorage.getItem('sub_agent') ? (
                                    // 	<List.Item
                                    // 		style={{
                                    // 			borderColor: '#EC4121',
                                    // 		}}
                                    // 	>
                                    // 		<List.Item.Meta
                                    // 			avatar={
                                    // 				<Avatar
                                    // 					src={data[5].avatar}
                                    // 					size="large"
                                    // 				/>
                                    // 			}
                                    // 			title={data[5].title}
                                    // 			description={
                                    // 				<>
                                    // 					<h3>
                                    // 						{data[5].description}
                                    // 					</h3>
                                    // 					<h4>
                                    // 						<IntlMessages id="wallet.parent-agent" />
                                    // 						:{' '}
                                    // 						{secureStorage.getItem(
                                    // 							'parent_agent'
                                    // 						)}
                                    // 					</h4>
                                    // 				</>
                                    // 			}
                                    // 		/>
                                    // 	</List.Item>
                                    // ) : null

                                    null}
                                  </List>
                                </Col>
                                <Col
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                  style={colStyle}
                                >
                                  <IsoWidgetsWrapper>
                                    {/* VCard Widget */}

                                    <Card
                                      style={{
                                        borderRadius: 20,
                                        borderColor: "#EC4121",
                                      }}
                                      title={
                                        <IntlMessages id="wallet.contact-us" />
                                      }
                                    >
                                      {/* <p style={{ marginBottom: 10 }}>
																				<Avatar
																					src="/icons/phone.png"
																					size={30}
																				/>
																				&nbsp;&nbsp;
																				<b>+977-9844347777</b>
																			</p> */}
                                      <p
                                        style={{
                                          marginBottom: -10,
                                          color: "green",
                                        }}
                                      >
                                        <Avatar
                                          src="/icons/telephone.png"
                                          size={30}
                                        />
                                        &nbsp;&nbsp; <b>+977-1-4488357,</b>
                                      </p>
                                      <p
                                        style={{
                                          marginLeft: 40,
                                          marginBottom: -2,
                                          color: "green",
                                        }}
                                      >
                                        <b>+977-1-4488358,</b>
                                      </p>
                                      <p
                                        style={{
                                          marginLeft: 40,
                                          marginBottom: 10,
                                          color: "green",
                                        }}
                                      >
                                        <b>+977-1-4620147</b>
                                      </p>
                                      <p style={{ marginBottom: 10 }}>
                                        <Avatar
                                          src="/icons/email.png"
                                          size={30}
                                        />
                                        &nbsp;&nbsp;
                                        <a
                                          href="mailto:care@dpaisa.com?Subject=DPaisa%20Contact%20Us"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          care@dpaisa.com
                                        </a>
                                      </p>
                                    </Card>
                                  </IsoWidgetsWrapper>
                                </Col>
                                <Col
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                  style={colStyle}
                                >
                                  <IsoWidgetsWrapper>
                                    <p
                                      style={{ marginLeft: 10, color: "gray" }}
                                    >
                                      <b>
                                        {this.state.generals &&
                                          this.state.generals.map(
                                            (gen, index) => {
                                              return (
                                                <a
                                                  href={`/dashboard/generalPages/${gen.id}`}
                                                  style={{
                                                    color: "gray",
                                                  }}
                                                >
                                                  {gen.title}
                                                  {index ===
                                                  this.state.generals.length -
                                                    1 ? null : (
                                                    <b
                                                      style={{
                                                        fontSize: 20,
                                                      }}
                                                    >
                                                      {" "}
                                                      .{" "}
                                                    </b>
                                                  )}
                                                </a>
                                              );
                                            }
                                          )}
                                      </b>
                                    </p>
                                    <p
                                      style={{
                                        marginLeft: 10,
                                        color: "gray",
                                        marginTop: 10,
                                      }}
                                    >
                                      <b>
                                        d-पैसा © 2019 Powered by{" "}
                                        <a
                                          href="http://goldmine.com.np"
                                          target="_blank"
                                          style={{ color: "gray" }}
                                          rel="noopener noreferrer"
                                        >
                                          Gold Mine Business Group.
                                        </a>
                                      </b>
                                    </p>
                                  </IsoWidgetsWrapper>
                                </Col>
                              </Row>
                            </Col>
                          )}

                          {/* right sidebar end */}
                        </Row>
                      </div>
                    </LayoutWrapper>
                  </Content>

                  {/* <Footer
                style={{
                  background: '#ffffff',
                  textAlign: 'center',
                  borderTop: '1px solid #ededed'
                }}
              >
                {siteConfig.footerText}
              </Footer> */}
                </Layout>
              </Layout>
            </Spin>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  (state) => ({
    height: state.App.height,
    wallet: state.Wallet,
    not_count: state.Notification.requestLength,
    currentUser: state.CurrentUser,
    loading: state.CurrentUser.loading,
    unauthorized: state.CurrentUser.unauthorized,
    bonus: state.BonusPoints.bonus,
    bonusList: state.BonusPoints.bonusList,
  }),
  {
    getCurrentUser,
    getRequests,
    responseWallet,
    updateNotificationCount,
    addToWallet,
    logout,
    toggleAll,
    resetUser,
    getBonusList,
    getCashbacks,
  }
)(App);
