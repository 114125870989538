import axios from '../../../../axios/index';
import secureStorage from '../../../../containers/LocalStorage';
export const updateWallet = (amount) => {
	return (dispatch, getState) => {
		try {
			dispatch({ type: 'UPDATE_WALLET', amount });
		} catch (err) {
			dispatch({ type: 'UPDATE_WALLET_ERROR', err });
		}
	};
};
export const addToWallet = (amount) => {
	return (dispatch, getState) => {
		try {
			dispatch({ type: 'ADD_TO_WALLET', amount });
		} catch (err) {
			dispatch({ type: 'UPDATE_WALLET_ERROR', err });
		}
	};
};

export const responseWallet = () => {
	return (dispatch, getState) => {
		axios
			.get(`api/wallet`, {
				headers: {
					Authorization: 'Bearer ' + secureStorage.getItem('id_token'),
				},
			})
			.then(({ data }) => {
				let balance = data.data.balance / 100;
				let bonus_balance = data.data.bonus_balance / 100;
				let wallet = {
					balance,
					bonus_balance,
				};
				dispatch({ type: 'RESPONSE_WALLET', wallet });
			})
			.catch((err) => {
				dispatch({ type: 'RESPONSE_WALLET_ERROR' });
			});
	};
};
