import React, { Component } from "react";
import { Row, Col, Spin, Input } from "antd";
import basicStyle from "../../settings/basicStyle";
import Box from "../../components/utility/box";
import Form from "../../components/uielements/form";
import Button from "../../components/uielements/button";
import Notification from "../../components/notification";
import axios from "../../axios/index";
import secureStorage from "../LocalStorage";
const FormItem = Form.Item;

class EnterAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      seconds: 30,
      resend: true,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      if (secureStorage.getItem("verified") === null) {
        this.sendOtp();
      }
    }, 1500);
  }

  sendOtp = async () => {
    this.setState({
      loading: true,
      seconds: 30,
      resend: false,
    });
    try {
      let sendOTP = await axios.post(
        `api/phone/resend`,
        { email: secureStorage.getItem("mobile_no") },
        {
          headers: {
            Authorization: "Bearer " + secureStorage.getItem("id_token"),
          },
        }
      );
      console.log("sendOTP", sendOTP.data);
      await this.showNotification(
        "success",
        "An OTP Code has been sent to your phone number, please check your phone!!"
      );
      await this.setTime();
    } catch (e) {
      this.showNotification("error", "OTP code not sent!!");
      console.log("erroe", e);
    }
    this.setState({
      loading: false,
    });
  };
  verifyOTP = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        try {
          await axios.post(
            `api/phone/verify`,
            { code: values.code },
            {
              headers: {
                Authorization: "Bearer " + secureStorage.getItem("id_token"),
              },
            }
          );
          this.showNotification(
            "success",
            "Phone number Verified Successfully!!!"
          );

          //secureStorage.setItem("verified", "doine")
          window.location.reload(true);
        } catch (e) {
          console.log("error_verify", e);
          this.showNotification("error", "Phone number not Verified!");
        }
        this.setState({
          loading: false,
        });
      }
    });
  };
  showNotification = async (type, message) => {
    await Notification(type, message);
  };
  setTime = () => {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        this.setState({
          resend: true,
        });
        clearInterval(this.myInterval);
      }
    }, 1000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };
    const { rowStyle, colStyle } = basicStyle;
    const { seconds } = this.state;
    console.log("resend", this.state.resend);
    return (
      <Row style={rowStyle} gutter={0} justify="start">
        <Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
          <Spin spinning={this.state.loading}>
            <Box style={{ borderRadius: 10, borderColor: "#EC4121" }}>
              <center>
                <h3>Verify Your Account</h3>
              </center>
              <center>
                <h4 style={{ marginBottom: 30 }}>
                  <i>
                    (An OTP code has been be sent to{" "}
                    {secureStorage.getItem("mobile_no")}, please check your
                    phone.)
                  </i>
                </h4>
              </center>
              <Form onSubmit={this.verifyOTP}>
                <FormItem {...formItemLayout} label="Enter OTP" hasFeedback>
                  {getFieldDecorator("code", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your OTP Code!",
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (/\s/.test(value)) {
                            callback("OTP can not have spaces!!");
                          } else if (/[^0-9]/.test(value)) {
                            callback(
                              "Invalid OTP, can not contain special characters!"
                            );
                          } else {
                            callback();
                          }
                        },
                      },
                      {
                        min: 5,
                        message: "OTP must be of only 5 digits",
                      },
                      {
                        max: 5,
                        message: "OTP must be of only 5 digits",
                      },
                    ],
                  })(<Input type="text" placeholder="OTP Code" />)}
                </FormItem>

                <FormItem {...tailFormItemLayout}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={this.state.loading}
                  >
                    Verify OTP
                  </Button>
                  &nbsp;
                  <Button
                    onClick={this.sendOtp}
                    loading={this.state.loading}
                    disabled={this.state.resend ? false : true}
                  >
                    {this.state.resend === false
                      ? "Resend OTP After " + seconds + " seconds"
                      : "Send OTP"}
                  </Button>
                </FormItem>
              </Form>
            </Box>
          </Spin>
        </Col>
      </Row>
    );
  }
}

const AddAmount = Form.create()(EnterAmount);

export default AddAmount;
