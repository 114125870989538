import firebase from 'firebase';

const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: FIREBASE_KEY,
    authDomain: "dpaisa-cf529.firebaseapp.com",
    databaseURL: "https://dpaisa-cf529.firebaseio.com",
    projectId: "dpaisa-cf529",
    storageBucket: "dpaisa-cf529.appspot.com",
    messagingSenderId: "623055048139",
    appId: "1:623055048139:web:b2fb67d53f10b1efe8ba4e",
    measurementId: "G-KBR4P4TJ64"
  });
}


export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    //await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('token do usuário:', token);
    
    return token;
  } catch (error) {
    console.error(error);
  }
}