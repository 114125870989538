import axios from '../../../../axios/index';
import secureStorage from '../../../../containers/LocalStorage';
export const getCurrentUser = () => {
	return (dispatch, getState) => {
		axios
			.get(`api/current-user-info`, {
				headers: {
					Authorization: 'Bearer ' + secureStorage.getItem('id_token'),
				},
			})
			.then(({ data }) => {
				//console.log("data", data.data);
				secureStorage.setItem('name', data.data.name);
				secureStorage.setItem('email', data.data.email);
				secureStorage.setItem('mobile_no', data.data.mobile_no);
				secureStorage.setItem('verified', data.data.phone_verified_at);

				if (data.data.kyc_status) {
					secureStorage.setItem('kyc', data.data.kyc_status);
				} else {
					secureStorage.setItem('kyc', null);
				}

				if (data.data.avatar) {
					secureStorage.setItem('avatar', data.data.avatar);
				} else {
					secureStorage.setItem('avatar', null);
				}
				secureStorage.setItem('email_verified', data.data.email_verified_at);
				secureStorage.setItem('pin', data.data.pin);
				secureStorage.setItem('agent', data.data.agent);
				secureStorage.setItem('agent_code', data.data.agent_code);
				secureStorage.setItem('sub_agent', data.data.sub_agent);
				secureStorage.setItem('parent_agent', data.data.parent_agent);
				secureStorage.setItem('transaction_pin', data.data.pin_status.transaction_pin);
				secureStorage.setItem('login_pin', data.data.pin_status.login_pin);
				secureStorage.setItem('referral_code', data.data.referral_code);
				secureStorage.setItem('referral_eligible', data.data.referral_eligible);
				secureStorage.setItem('load_limit', data.data.min_load_limit);
				secureStorage.setItem('payment_limit', data.data.min_payment_limit);
				let amount = data.data.balance / 100;
				let bonus_balance = data.data.bonus_balance / 100;
				let bonus = data.data.bonus;
				let bank_comission = data.data.bank_transfer_commission;
				let cnp_comission = data.data.card_load_commission;

				let final_wallet = { amount, bonus_balance };
				if (data.data.kyc) {
					let kyc = data.data.kyc;
					let banners = data.data.banners;
					dispatch({ type: 'GET_BANNERS_SUCCESS', banners });
					dispatch({ type: 'GET_CURRENT_USER_SUCCESS', kyc });
				} else {
					dispatch({ type: 'STOP_LOADING' });
				}
				dispatch({ type: 'GET_BONUS', bonus });
				dispatch({ type: 'GET_WALLET_SUCCESS', final_wallet });
				dispatch({ type: 'SAVE_BANK_COMISSION', bank_comission });
				dispatch({ type: 'SAVE_CNP_COMISSION', cnp_comission });
			})
			.catch((err) => {
				dispatch({ type: 'GET_CURRENT_USER_ERROR', err });
			});
	};
};
export const resetUser = () => {
	return (dispatch, state) => {
		dispatch({ type: 'RESET_USER' });
	};
};
