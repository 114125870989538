let scripts = {
  loadScripts: [
    "/landing/js/jquery-3.0.0.min.js",
    "/landing/js/jquery-migrate-3.0.0.min.js",
    "/landing/js/popper.min.js",
    "/landing/js/bootstrap.min.js",
    "/landing/js/scrollIt.min.js",
    "/landing/js/jquery.waypoints.min.js",
    "/landing/js/owl.carousel.min.js",
    "/landing/js/jquery.magnific-popup.min.js",
    "/landing/js/jquery.stellar.min.js",
    "/landing/js/isotope.pkgd.min.js",
    "/landing/js/YouTubePopUp.jquery.js",
    "/landing/js/validator.js",
    "/landing/js/scripts.js",
  ],
  css: ["/landing/css/plugins.css", "/landing/css/style.css"],
};

let loadScript = (url) => {
  setTimeout(() => {
    const script = document.createElement("script");
    script.src = url;
    script.id = url;
    script.async = true;
    document.body.appendChild(script);
  }, 300);
};
let loadCSS = (url) => {
  const script = document.createElement("link");
  script.href = url;
  script.id = url;
  script.rel = "stylesheet";
  document.head.appendChild(script);
};

let removeCSS = (script) => {
  if (document.getElementById(script)) {
    document.getElementById(script).remove();
  }
};

let removeScript = (script) => {
  if (document.getElementById(script)) {
    document.getElementById(script).remove();
  }
};

export function addIncludes() {
  scripts.loadScripts.map((val) => loadScript(val));
  scripts.css.map((val) => loadCSS(val));
}
export function removeIncludes() {
  scripts.loadScripts.map((val) => removeScript(val));
  scripts.css.map((val) => removeCSS(val));
}
export function fixLoading() {
  if (window.location.pathname === "/") {
    addIncludes();
  } else {
    removeIncludes();
  }
}
