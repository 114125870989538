//import secureStorage from '../LocalStorage';

import secureStorage from '../LocalStorage';

const options = [
	{
		key: 'home',
		label: 'menu.home',
		leftIcon: 'ion-home',
	},
	{
		key: 'services',
		label: 'menu.services',
		leftIcon: 'ion-android-checkbox-outline',
		children: [
			{
				key: 'topup',
				label: 'utility.topup',
			},
			{
				key: 'landline',
				label: 'utility.landline',
			},
			{
				key: 'electricity',
				label: 'utility.nea',
			},
			{
				key: 'khanepani',
				label: 'utility.khanepani',
			},
			{
				key: 'nepalWater',
				label: 'utility.nepal-water',
			},
			{
				key: 'NtcEpin',
				label: 'utility.ntc-epin',
			},
			{
				key: 'SmartCellEpin',
				label: 'utility.smart-epin',
			},
			{
				key: 'NcellData',
				label: 'utility.ncell-data',
			},
			{
				key: 'adsl',
				label: 'utility.adsl',
			},
			{
				key: 'arrowNet',
				label: 'utility.arrow',
			},
			{
				key: 'sky/Internet',
				label: 'utility.sky-internet',
			},
			{
				key: 'webSurfer',
				label: 'utility.web-surfer',
			},
			{
				key: 'subisu/Internet',
				label: 'utility.subisu-internet',
			},
			{
				key: 'worldLink',
				label: 'utility.worldlink',
			},
			{
				key: 'vianet',
				label: 'utility.vianet',
			},
			{
				key: 'wimax',
				label: 'utility.wimax',
			},
			{
				key: 'ntftth',
				label: 'utility.ftth',
			},
			{
				key: 'sky/Television',
				label: 'utility.sky-tv',
			},
			{
				key: 'subisu/Television',
				label: 'utility.subisu-tv',
			},
			{
				key: 'dishHome',
				label: 'utility.dish-home',
			},
			{
				key: 'DishHomeEpin',
				label: 'utility.dish-epin',
			},
			{
				key: 'simTV',
				label: 'utility.sim-tv',
			},
			{
				key: 'NetTv',
				label: 'utility.net-tv',
			},
			{
				key: 'MeroTv',
				label: 'utility.mero-tv',
			},
			{
				key: 'IRD',
				label: 'utility.ird',
			},
			{
				key: 'Passport',
				label: 'utility.passport',
			},
			{
				key: 'Loksewa',
				label: 'utility.loksewa',
			},
			{
				key: 'CRO',
				label: 'utility.cro',
			},
			{
				key: 'FCGO',
				label: 'utility.fcgo',
			},
			{
				key: 'DOFE',
				label: 'utility.dofe',
			},
			{
				key: 'TrafficFine',
				label: 'utility.traffic-fine',
			},
			{
				key: 'magnus/list',
				label: 'utility.magnus',
			},
		],
	},
	{
		key: 'transactionHistory',
		label: 'menu.transactions',
		leftIcon: 'ion-document-text',
	},
	// {
	// 	key: 'cooperativeStatement',
	// 	label: 'menu.cooperative-statement',
	// 	leftIcon: 'ion-document-text',
	// },
	{
		key: 'wallet',
		label: 'menu.wallet',
		leftIcon: 'ion-bag',
		children: [
			{
				key: 'loadFunds',
				label: 'home.load-funds',
			},
			{
				key: 'sendFunds',
				label:
					secureStorage.getItem('agent') === 'ACCEPTED' || secureStorage.getItem('sub_agent') === 'ACCEPTED'
						? 'home.cash-out'
						: 'home.send-funds',
			},
			{
				key: 'loadFunds/request',
				label: 'home.request-funds',
			},
			{
				key: 'bankDeposit',
				label: 'home.bank-deposit',
			},
		],
	},
	{
		key: 'account',
		label: 'menu.account',
		leftIcon: 'ion-person',
		children: [
			{
				key: 'profile',
				label: 'topbar.profile',
			},
			{
				key: 'settings',
				label: 'topbar.settings',
			},
			{
				key: 'qrcode',
				label: 'menu.qr',
			},
			// {
			// 	key: secureStorage.getItem('referral_code') ? 'referral' : null,
			// 	label: 'Referral',
			// },
		],
	},
	{
		key: 'limits',
		label: 'menu.limits',
		leftIcon: 'ion-arrow-graph-up-right',
	},
];
export default options;
