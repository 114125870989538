const initState = {
	wallet: 0,
	bonus_balance: 0,
	loading: true,
};

const walletReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_WALLET_SUCCESS':
			//console.log('wallet_success', action);
			return {
				...state,
				wallet: action.final_wallet.amount,
				bonus_balance: action.final_wallet.bonus_balance,
				loading: false,
			};
		case 'GET_WALLET_ERROR':
			console.log('request_error', action.err);
			return { state };
		case 'UPDATE_WALLET':
			let temp = state.wallet - action.amount;
			//console.log('update_wallet', temp)
			return {
				...state,
				wallet: temp,
			};
		case 'RESPONSE_WALLET':
			console.log('response wallet success', action);
			return {
				...state,
				wallet: action.wallet.balance,
				bonus_balance: action.wallet.bonus_balance,
			};
		case 'RESPONSE_WALLET_ERROR':
			console.log('response wallet error');
			return state;
		case 'ADD_TO_WALLET':
			let amt = parseFloat(state.wallet) + parseFloat(action.amount);
			return {
				...state,
				wallet: amt,
			};
		case 'UPDATE_WALLET_ERROR':
			console.log('updateWalleterror');
			return state;
		default:
			return state;
	}
};

export default walletReducer;
