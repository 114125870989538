import React from "react";
import { Link } from "react-router-dom";
//import { siteConfig } from '../../settings';

export default ({ collapsed }) => {
  return (
    <div
      className="isoLogoWrapper ant-card-bordered"
      style={{
        paddingTop: 3,
        background: "rgba(57, 57, 58, 0.1)",
        borderColor: "rgb(57, 57, 58) rgb(57, 57, 58) rgb(255, 255, 255)",
      }}
    >
      {collapsed ? (
        <Link to="/dashboard">
          <center>
            <img
              src="/favicon.png"
              style={{
                maxWidth: 60,
                maxHeight: 60,
                // border: "2px solid #EC4121",
                // borderRadius: 10
              }}
              alt="logo"
            />
          </center>
        </Link>
      ) : (
        <Link to="/dashboard/home">
          {/* {siteConfig.siteName} */}
          <img
            src="/new_try.png"
            style={{
              maxWidth: 160,
              //height: 67,

              // border: "1px solid #EC4121",
              // borderRadius: 10
            }}
            alt="logo"
          />
        </Link>
      )}
    </div>
  );
};
