import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { clearToken } from '../../helpers/utility';
import actions from './actions';
import { loginUser } from '../../api/users';
import secureStorage from '../../containers/LocalStorage';
import Cookies from 'js-cookie';


export function* loginRequest() {
	yield takeEvery('LOGIN_REQUEST', function*(action) {
		
		try {
			let user = yield call(loginUser, action.payload);
			yield put({
				type: actions.LOGIN_SUCCESS,
				user,
			});
			console.log(user,19999999999);
		} catch ({ response }) {
			yield put({ type: actions.LOGIN_ERROR, error: { status: response } });
		}
	});
}

export function* loginSuccess() {
	 yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {


		 if (payload.user.user !== undefined) {
			
			
			var expiry = new Date(payload.user.expires_at);
			console.log(payload)
			
			yield Cookies.set('token', payload.user.access_token, { expires: expiry });
			 yield secureStorage.setItem('id_token', payload.user.access_token);
			
			yield secureStorage.setItem('name', payload.user.user.name);
			
			yield secureStorage.setItem('email', payload.user.user.email);
			yield secureStorage.setItem('mobile_no', payload.user.user.mobile_no);
			yield secureStorage.setItem('agent', payload.user.user.agent);
			yield secureStorage.setItem('agent_code', payload.user.user.agent_code);
			yield secureStorage.setItem('sub_agent', payload.user.user.sub_agent);
			yield secureStorage.setItem('parent_agent', payload.user.user.parent_agent);
			yield secureStorage.setItem('created_at', payload.user.user.created_at);
			yield secureStorage.setItem('verified', payload.user.user.phone_verified_at);
			yield secureStorage.setItem('email_verified', payload.user.user.email_verified_at);
			yield secureStorage.setItem('pin', payload.user.user.pin);
			yield secureStorage.setItem('referral_code', payload.user.user.referral_code);
			
			yield secureStorage.setItem('kyc', payload.user.user.kyc_status);
			yield secureStorage.setItem('referral_eligible', payload.user.user.referral_eligible);
			yield secureStorage.setItem('transaction_pin', payload.user.user.pin_status.transaction_pin);
			yield secureStorage.setItem('login_pin', payload.user.user.pin_status.login_pin);
			yield secureStorage.setItem('load_limit', payload.user.user.min_load_limit);
			yield secureStorage.setItem('payment_limit', payload.user.user.min_payment_limit);
			yield secureStorage.setItem('gender', payload.user.user.gender);
			
			
	
		}

		// console.log('phone', payload.user.user.mobile_no)
		
		 yield put(push('/dashboard/home/'));
	});
}

export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function*() {
		yield put(push('/signin'));
	});
}

export function* logout() {
	yield takeEvery(actions.LOGOUT, function*() {
		clearToken();
		secureStorage.removeItem('id_token');
		secureStorage.removeItem('name');
		secureStorage.removeItem('email');
		secureStorage.removeItem('mobile_no');
		secureStorage.removeItem('agent');
		secureStorage.removeItem('agent_code');
		secureStorage.removeItem('sub_agent');
		secureStorage.removeItem('parent_agent');
		secureStorage.removeItem('created_at');
		secureStorage.removeItem('verified');
		secureStorage.removeItem('email_verified');
		secureStorage.removeItem('pin');
		secureStorage.removeItem('referral_code');
		secureStorage.removeItem('kyc');
		secureStorage.removeItem('referral_eligible');
		secureStorage.removeItem('transaction_pin');
		secureStorage.removeItem('login_pin');
		Cookies.remove('token');
		yield put(push('/signin'));
	});
}
export function* checkAuthorization() {
	yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
		const token = secureStorage.getItem('id_token');
		//console.log('tokenwaaa', token)
		if (token) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token,
				profile: 'Profile',
			});
		}
	});
}
export default function* rootSaga() {
	yield all([fork(checkAuthorization), fork(loginRequest), fork(loginSuccess), fork(loginError), fork(logout)]);
}
