import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import { Link } from "react-router-dom";
import { Icon, Badge } from "antd";
import secureStorage from "../LocalStorage";
import LanguageSwitcher from "../LanguageSwitcher";
const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      not_count: this.props.not_count,
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      not_count: props.not_count,
    });
  }
  render() {
    const { toggleCollapsed } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background:
        secureStorage.getItem("agent") === "ACCEPTED" ||
        secureStorage.getItem("sub_agent") === "ACCEPTED"
          ? "rgba(0,0,0,0.9)"
          : "#EC4121",
      position: "fixed",
      width: "100%",
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            {secureStorage.getItem("verified") !== null ? (
              <button
                className={
                  collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                }
                style={{ color: "#fff" }}
                onClick={toggleCollapsed}
              />
            ) : null}
          </div>

          <ul className="isoRight">
            <li
              //onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
              style={{ marginTop: 20 }}
            >
              <LanguageSwitcher />
            </li>
            <li
              //onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
              style={{ marginTop: 20 }}
            >
              <Link to="/dashboard/notifications">
                <Badge count={this.state.not_count}>
                  <Icon type="bell" style={{ color: "#fff", fontSize: 25 }} />
                </Badge>
              </Link>
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
              style={{ marginTop: 20 }}
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
  }),
  { toggleCollapsed }
)(Topbar);
