const initState = {
	kyc: null,
	loading: true,
	unauthorized: false,
	banners: [],
	bank_comission: [],
	cnp_comission: {},
};

const currentUserReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_CURRENT_USER_SUCCESS':
			//console.log('USER_SUCCESS', action)
			return {
				...state,
				loading: false,
				kyc: action.kyc,
			};
		case 'STOP_LOADING':
			//console.log('USER_SUCCESS', action)
			return {
				...state,
				loading: false,
			};
		case 'SAVE_BANK_COMISSION':
			//console.log('USER_SUCCESS', action)
			return {
				...state,
				bank_comission: action.bank_comission,
			};
		case 'SAVE_CNP_COMISSION':
			//console.log('USER_SUCCESS', action)
			return {
				...state,
				cnp_comission: action.cnp_comission,
			};
		case 'GET_CURRENT_USER_ERROR':
			return { ...state, unauthorized: true, loading: false };
		case 'GET_BANNERS_SUCCESS':
			return {
				...state,
				banners: action.banners,
			};
		case 'RESET_USER':
			return { ...state, unauthorized: false, loading: true, kyc: null };
		default:
			return state;
	}
};

export default currentUserReducer;
