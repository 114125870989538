
const initState = {
    requestStatus: null,
    loading: false
}

const requestReducer = (state = initState, action) => {
    switch (action.type) {
        case "SEND_REQUEST_SUCCESS":
            console.log('request_success')
            return {
                ...state,
                requestStatus: "Success",
                loading: false
            }
        case "SEND_REQUEST_ERROR":
            console.log('request_error', action.err)
            return {
                ...state,
                requestStatus: "Failed",
                loading: false
            }
        case "RESET_REQUEST":
            return {
                ...state,
                requestStatus: null
            }
        case "START_REQUEST_LOADING":
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}

export default requestReducer; 