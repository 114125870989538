import axios from "../../../../axios/index";
import secureStorage from "../../../../containers/LocalStorage";

export const getBonusPoints = (bonus) => {
	return (dispatch, getState) => {
		dispatch({ type: 'GET_BONUS', bonus });
	};
};

export const getBonusList = (list) => {
	return (dispatch, getState) => {
		dispatch({ type: 'GET_BONUS_LIST', list });
	};
};

export const myBonus = () => {
	return (dispatch, getState) => {
		axios
			.get(`api/bonus`, {
				headers: {
					Authorization: 'Bearer ' + secureStorage.getItem('id_token'),
				},
			})
			.then(({ data }) => {
				let bonus = data.data.bonus.bonus;
				dispatch({ type: 'GET_BONUS', bonus });
			})
			.catch((err) => {
				dispatch({ type: 'GET_BONUS_ERROR', err });
			});
	};
};
