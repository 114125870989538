import axios from "../axios/index";
import { loginApi } from "../containers/UrlConfig";
function loginUser(payload) {
  let final = {};
  if (payload.type === "email") {
    if (payload.password.length === 4 && !isNaN(payload.password)) {
      final = {
        email: payload.email,
        pin: payload.password,
        desktop_fcm: payload.desktop_fcm,
      };
    } else {
      final = {
        email: payload.email,
        password: payload.password,
        desktop_fcm: payload.desktop_fcm,
      };
    }
  } else {
    if (payload.password.length === 4 && !isNaN(payload.password)) {
      final = {
        mobile_no: payload.mobile_no,
        pin: payload.password,
        desktop_fcm: payload.desktop_fcm,
      };
    } else {
      final = {
        mobile_no: payload.mobile_no,
        password: payload.password,
        desktop_fcm: payload.desktop_fcm,
      };
    }
  }
  return axios
    .post(loginApi, final, {
      headers: { "Access-Control-Allow-Origin": "https://dpaisa.com" },
    })
    .then(({ data }) => {
      // console.log("loginuser", data);
      return data;
    });
}

export { loginUser };
