const initState = {
    sendStatus: null,
    loading: false
}

const sendFundReducer = (state = initState, action) => {
    switch (action.type) {

        case "FUND_TRANSFER_SUCCESS":
            console.log('transfer_success')
            return {
                ...state,
                sendStatus: "Success",
                loading: false
            }
        case "FUND_TRANSFER_FAILED":
            console.log('transfer_failed')
            return {
                ...state,
                sendStatus: "Failed",
                loading: false
            }
        case "RESET_TRANSFER":
            console.log('transfer_reset')
            return {
                ...state,
                sendStatus: null
            }
        case "START_LOADING":
            console.log('load_start')
            return {
                ...state,
                loading: true
            }
        case "STOP_LOADING":
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }

}

export default sendFundReducer